<template>
    <v-user-table :data-function="getRoleUsers" />
</template>

<script>
import UserTable from "@/components/application/user/user-table.vue";
import RoleService from "@/services/modules/role-service";

export default {
    components: {
        "v-user-table": UserTable,
    },
    props: {
        role: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            getRoleUsers: () => (RoleService.getUsers({ role_id: this.role.role_id })),
        };
    },
};
</script>
